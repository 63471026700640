
import { getApiBaseUrl, getApiClient } from "./serviceconfig";
// var apiBaseUrl = "http://localhost:8001";
var apiBaseUrl = getApiBaseUrl();

export async function getAllCheckOut(cookies,searchString) {

  let url= `${apiBaseUrl}/checkout/`;
  if (searchString) {
    url = url + `?name=${searchString}`;
  }
    return getApiClient(cookies).get(url)
      .then(response => response.data);
}


export async function createCheckOut(cookies, inputs) {
  console.log(JSON.stringify(inputs)+"inputs_services")

  return getApiClient(cookies).post(`${apiBaseUrl}/checkout/`,inputs)
  
  
}

// export async function getCheckOutNumber(cookies, plantId,movement_type) {
  export async function getCheckOutNumber(cookies, plantId) {

  return getApiClient(cookies)
  // .get(`${apiBaseUrl}/checkout/checkout_number/?plant_id=${plantId}&movement_type=${movement_type}`)
  .get(`${apiBaseUrl}/checkout/checkout_number/?plant_id=${plantId}`)
  .then(response => response.data);
}
export async function getCheckOutForCHeckIn(cookies, plantId) {

  return getApiClient(cookies)
  // .get(`${apiBaseUrl}/checkout/checkout_number/?plant_id=${plantId}&movement_type=${movement_type}`)
  .get(`${apiBaseUrl}/checkout/check_in/?plant_id=${plantId}`)
  .then(response => response.data);
}

export async function updateCheckOut(cookies, inputs) {

    return getApiClient(cookies)
    .put(`${apiBaseUrl}/checkout/${inputs.id}/`,inputs)
}
  


export async function getCheckOutDetails(cookies, CheckOutId) {

  return getApiClient(cookies).get(`${apiBaseUrl}/checkout/${CheckOutId}/`)
  .then(response => response.data)
}

export async function deleteCheckOut(cookies, CheckOutId) {

  return getApiClient(cookies).delete(
    `${apiBaseUrl}/checkout/${CheckOutId}/`)
}


export async function printDcCheckout(cookies, CheckOutId) {
  return getApiClient(cookies).get(`${apiBaseUrl}/checkout/${CheckOutId}/print_dc/`)
              .then(response => response.data)
}

export async function printBatchReport(cookies, CheckOutId) {
  return getApiClient(cookies).get(`${apiBaseUrl}/checkout/${CheckOutId}/batch_report/`)
              .then(response => response.data)
}



